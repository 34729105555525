import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import tilt from "tilt.js";

gsap.registerPlugin(ScrollTrigger);
const $ = require("jquery");
require("jquery.marquee");

function checkPhoneWidth() {
  const boundary = 400;
  return window.innerWidth <= boundary;
}

$(document).ready(function () {
  $(".marquee").marquee({
    //duration in milliseconds of the marquee
    duration: 15000,
    //gap in pixels between the tickers
    gap: 50,
    //time in milliseconds before the marquee will start animating
    delayBeforeStart: 0,
    //'left' or 'right'
    direction: "left",
    //true or false - should the marquee be duplicated to show an effect of continues flow
    duplicated: true,
  });

  tilt();

  const isMobile = checkPhoneWidth();
  const player = videojs("promo");

  if (isMobile) {
    player.fill(true);
    player.fluid(true);
    player.aspectRatio("16:9");
    player.playsinline(true);
    player.muted(true);
    player.autoplay('muted');
    player.play();
  }

  // const lineWidth = 30 + 4;
  // const totalLines = Math.floor(window.innerWidth / lineWidth);
  // const lines = [...new Array(totalLines).keys()].map((item) => {
  //   return `<div class="line" style="height: ${
  //     (item / totalLines) * 100
  //   }%; width: 30px; margin-right: 4px;"></div>`;
  // });
  //
  // $('.lines').html(lines.join(""));
  //
  // const tl = gsap.timeline({
  //   scrollTrigger: {
  //     trigger: ".lines",
  //     start: "center bottom",
  //     end: "center top",
  //     scrub: true,
  //     markers: true
  //   }
  // });
  // tl.to(".line", {yPercent: 100, duration: 1})
});
